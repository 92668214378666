import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalVariable } from 'src/app/core/global';
import { Lightbox } from 'ngx-lightbox';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {


    public shareStufff: Subject<any> = new Subject()

    constructor(private location: Location, public lightbox: Lightbox) {
    }

    changeLanguage() {
        const language = this.getLocalData(GlobalVariable.selectedLanguage);
        console.log(language,'............language.....')
        console.log(GlobalVariable.french.short,'....GlobalVariable.french.short...')
        if (!!language) {
            if (language === GlobalVariable.french.short) {
                this.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.english.short);
            } else if (language === GlobalVariable.english.short) {
                this.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.french.short);
            } else {
                this.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.english.short);
            }
        } else {
            this.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.english.short);
        }

        // this.translate.setDefaultLang(this.getLocalData(GlobalVariable.selectedLanguage));
        // this.translate.use(this.getLocalData(GlobalVariable.selectedLanguage));
    }

    openLightBox(url) {
        if (url) {
            const album = {
                src: url,
                thumb: url
            };
            const imgArr: any = [album];
            this.lightbox.open(imgArr, 0);
        }
    }

    getTimeFromMinutes(time) {
        const start = new Date();
        start.setHours(0, time, 0, 0);
        return start;
    }

    getToken() {
        if (!!localStorage.getItem(GlobalVariable.tokenKey)) {
            return localStorage.getItem(GlobalVariable.tokenKey);
        } else {
            return '';
        }
    }


    opneNewTab(url) {
        const completeUrl = (`${ window.location.origin }/${url }`);
        window.open(completeUrl, '_blank');
    }

    getFirstLetter(text: string) {
        return text.charAt(0);
    }

    setToken(data) {
        localStorage.setItem(GlobalVariable.tokenKey, data);
    }

    /********* Set data in local storage **********/
    setLocalData(key: string, data: any, json?: boolean) {
        localStorage.setItem(key, json ? JSON.stringify(data) : data);
    }

    /********* Get data from local storage **********/
    getLocalData(key: string, json?: boolean) {
        let data: any = null;
        try {
            data = json
                    ? JSON.parse(localStorage.getItem(key))
                    : localStorage.getItem(key);
            return data;
        } catch (error) {
            if (error instanceof SyntaxError) {
                this.clearLocalData(key);
            }
            return null;
        }
    }

    clearAllLocalData() {
        localStorage.clear();
    }

    /********* Remove data from local storage **********/
    clearLocalData(key: string) {
        localStorage.removeItem(key);
    }

    goBack() {
        this.location.back();
    }

    goToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        window.scrollTo(0, 0);
    }

    getMobileNumber(phone) {
        let phoneNumber: any = phone.number;
        phoneNumber = parseInt(phoneNumber, 10);
        return phoneNumber;
    }

}
